<template>
    <!-- ============================ Hero Banner  Start #1CA4D4================================== -->
    <div class="hero-banner full jumbo-banner" style="background:#fff">
        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-7 col-md-8">
                    <div class="header-promo-text mb-3">The smartest way to </div>
                    <h1>To build your <span class="text-info">career.</span></h1>
                    <p class="lead">
                        Sabenzi offers an integrated and innovative approach to recruitment and talent management,
                        our creative solutions help streamline capacity building for businesses and individuals alike.
                    </p>

                    <div class="featured-category dark mt-4 d-none d-lg-block">
                        <ul>
                            <li>
                                <a :href="coreUrl.jobsUrl+'jobs'" data-toggle="tooltip" class="bg-secondary rounded-left" >
                                    Career Development
                                </a>
                            </li>
                            <li>
                                <router-link to="/for_organizations" data-toggle="tooltip" class="bg-primary rounded-right" >
                                    Talent Management
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-5 col-md-4 ">
                    <base-image
                        imgName="a-2.png"
                        imgGeo="home"
                        imgClass="img-fluid"
                        imgAlt="Hero"
                     >
                     </base-image>
                </div>

            </div>
        </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

    <!-- ============================ Newest Designs Start ==================================== -->
    <section class="min-sec gray-light" style="background:#fff">
        <div class="container " >

            <div class="row mt-3 pt-3 pb-3">
                <div class="col-lg-12 col-md-12">
                    <div class="">
                        <div class="float-left">
                            <h2>
                                 {{ activeJobInfo?'Explore featured ':'Explore job ' }}
                                <span class="theme-cl-2">
                                 {{ activeJobInfo?'jobs':'categories' }}
                                </span>
                            </h2>
                        </div>
                        <div class="float-right cursor-pointer d-none d-lg-block" @click="toggleActiveJobInfo">
                            <i class="bi " :class="activeJobInfo?'bi-stack':'bi-megaphone' "></i>
                            {{ activeJobInfo?'show categories':'show job offers' }}
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div class="container" v-if="activeJobInfo">

            <div class="row">

                <!-- Single Job List -->
                <div class="col-lg-6 col-md-6 col-sm-12"  v-for="job in featuredJobOffers" :key="job.id">
                    <div class="_jb_list72" style="background:#f5f5f5">
                        <div class="jobs-like bookmark">
                            <label class="toggler toggler-danger">
                                <input type="checkbox"><i class="bi bi-heart"></i>
                            </label>
                        </div>
                        <div class="_jb_list72_flex">
                            <div class="_jb_list72_first">
                                <div class="_jb_list72_yhumb small-thumb">
                                    <img src="https://via.placeholder.com/100x100" class="img-fluid" alt="">
                                </div>
                            </div>
                            <div class="_jb_list72_last">
                                <div class="_times_jb">{{ job.username.substr(0,100) }}</div>
                                <h4 class="_jb_title">

                                    <a :href="`${coreUrl.jobsUrl}job_details/${job.title.replace(/[^a-zA-Z0-9]/g,'_')}/${job.id}`">
                                        {{ job.title.substr(0,55) }}
                                        {{ job.title.length > 55 ? '....':''}}
                                    </a>

                                </h4>
                                <div class="_times_jb">{{ JSON.parse(job.job_location)['country'] }}, {{ JSON.parse(job.job_location)['city'] }}</div>
                                <ul class="_requirement_lists">
                                    <li>
                                        <span class="_elios req_1">
                                            {{ job.currency == 'n'?'&#8358;':'' }}
                                            {{ job.currency == '$'?'$':'' }}
                                            {{ job.currency == '£'?'£':'' }}
                                            {{ job.currency == 'c'?'&#8373;':'' }}
                                            {{ job.currency == 'e'?'&#8364;':'' }}
                                            {{ job.salary_status == "none" ||
                                               job.salary_status == "request_salary" ?
                                               'salary n/a ':job.salary.toLocaleString("en") + ' ' +  placeholderOptions[job.job_type]
                                            }}
                                        </span>
                                    </li>
                                    <li><span class="_elios req_2 text-white bg-secondary" style="opacity:0.4" >{{ job.job_type.replace('_','-') }}</span></li>
                                    <li>
                                        <span class="_elios req_6">
                                         {{ new Date(job.end_date).toLocaleDateString("en-US") }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="_jb_list72_foot">
                            <div class="_times_jb"></div>
                        </div>
                    </div>
                </div>

            </div> <!-- End featured jobs -->

        </div>

        <div class="container" v-else>


            <div class="row justify-content-center">

                <!-- Single category -->
                <div class="col-lg-3 col-md-4 col-sm-6" v-for="category in jobCategory" :key="category.value">
                    <div class="small-category-2 beige-bg pt-1 pb-3">
                        <div class="sc-2-detail ">
                            <h5 class="sc-jb-title">
                                <router-link to="/browse_jobs">
                                    {{ category.value }}
                                </router-link>
                            </h5>
                        </div>
                    </div>
                </div>


            </div>


        </div>


    </section>
    <!-- ============================ Newest Designs End ==================================== -->

    <!-- ============================ second  ind Start #1CA4D4================================== -->
    <div class="" style="background:#fff">
        <div class="container">
            <div class="row align-items-center" >

                <div class="col-12 d-none d-lg-block" style="padding-top:6em;"></div>
                <div class="col-lg-7 col-md-8">
                    <h1>Your<span class="text-info"> career</span> is our top priority.</h1>
                    <p class="lead">
                        Sabenzi offers an integrated and innovative approach to recruitment and talent management,
                        our creative solutions help streamline capacity building for businesses and individuals alike.
                    </p>

                </div>

                <div class="col-lg-5 col-md-4">
                    <base-image
                        imgName="side-img.png"
                        imgGeo="home"
                        imgClass="img-fluid"
                        imgAlt="Hero"
                     >
                     </base-image>
                </div>
                <div class="col-12 d-none d-lg-block" style="padding-bottom:10em"></div>

            </div>
        </div>
    </div>
    <!-- ============================ second ind Banner End ================================== -->

    <!-- ============================ third ind  Start ================================== -->
    <div class="" style="background:#f5f5f5">
        <div class="container">
            <div class="row align-items-center" >

                <div class="col-12 d-none d-lg-block" style="padding-top:5em;"></div>
                <div class="col-lg-9 col-md-8 pt-3 pb-3">
                    <p class="lead">
                        Get access to thousands of job opprtunities and capcity building knowledge.
                    </p>

                </div>

                <div class="col-lg-3 col-md-4 pb-3">
                    <div class="">
                            <a :href="coreUrl.accountUrl+'sign_up?platform=jobs'" class="_browse_more-light light">
                                Sign up to get Started
                            </a>
                    </div>
                </div>
                <div class="col-12 d-none d-lg-block" style="padding-bottom:5em"></div>

            </div>
        </div>
    </div>
    <!-- ============================ third ind Banner End ================================== -->

    <!-- ============================ Setup Process Start ==================================== -->
    <section >
        <div class="container pt-5 pb-5">
            <div class="row align-items-center">

                <div class="col-12 d-none d-lg-block" style="padding-top:6em;"></div>

                <div class="col-lg-5 col-md-6 pb-4">
                    <base-image
                        imgName="edu_2.png"
                        imgGeo="home"
                        imgClass="img-fluid"
                        imgAlt="TMS Hero"
                     >
                     </base-image>


                    <div class="mt-5 mb-5 text-center">
                            <a :href="coreUrl.accountUrl+'sign_up?platform=tms'" class="_browse_more-light light " >Sign up to get Started</a>
                    </div>
                </div>

                <div class="col-lg-7 col-md-6 p-4">
                    <div class="_setup_process">
                        <h2>Talent Management <span class="theme-cl-2">System</span></h2>
                        <p>
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                            praesentium voluptatum deleniti atque corrupti quos dolores et quas
                            molestias excepturi sint occaecati cupiditate non provident, similique
                            sunt in culpa qui officia.
                        </p>
                    </div>
                    <div class="row pl-3">

                        <div class="col-lg-5 col-md-12 col-sm-12 mt-5 mr-4 p-4 rounded light-w" >
                                <div class="ye_lk23">
                                    <i class="bi bi-funnel"></i>
                                </div>
                                <div class="yel_7bcx pt-3">
                                    <h4>Pro recruitment tools</h4>
                                    <p>
                                        At vero eos et accusamus et iusto odio dignissimos
                                        ducimus qui blanditiis praesentium.</p>

                                    <p class="mt-4 tiny_font" >
                                        <i class="bi bi-arrow-right-circle-fill"></i>
                                        <u> <router-link to="/for_organizations"> learn more </router-link></u>
                                    </p>
                                </div>
                        </div>

                        <div class="col-lg-5 col-md-12 col-sm-12 mt-5 mr-4 p-4 rounded light-w" >
                                <div class="ye_lk23">
                                    <i class="bi bi-funnel"></i>
                                </div>
                                <div class="yel_7bcx pt-3">
                                    <h4>Insightful feedback</h4>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.</p>
                                    <p class="mt-4 tiny_font" >
                                        <i class="bi bi-arrow-right-circle-fill"></i>
                                        <u> <router-link to="/for_organizations"> learn more </router-link></u>
                                    </p>
                                </div>
                        </div>

                        <div class="col-lg-5 col-md-12 col-sm-12 mt-5 mr-4 p-4 rounded light-w" >
                                <div class="ye_lk23">
                                    <i class="bi bi-sliders"></i>
                                </div>
                                <div class="yel_7bcx pt-3">
                                    <h4>Great team options</h4>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.</p>
                                    <p class="mt-4 tiny_font" >
                                        <i class="bi bi-arrow-right-circle-fill"></i>
                                        <u> <router-link to="/for_organizations"> learn more </router-link></u>
                                    </p>
                                </div>
                        </div>

                        <div class="col-lg-5 col-md-12 col-sm-12 mt-5 mr-4 p-4 rounded light-w" >
                                <div class="ye_lk23">
                                    <i class="bi bi-credit-card-2-front-fill"></i>
                                </div>
                                <div class="yel_7bcx pt-3">
                                    <h4>Affordable services</h4>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.</p>
                                    <p class="mt-4 tiny_font" >
                                        <i class="bi bi-arrow-right-circle-fill"></i>
                                        <u> <router-link to="/for_organizations"> learn more </router-link></u>
                                    </p>
                                </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Featured Themes End ==================================== -->

    <!-- ============================ News Updates Start ==================================== -->
    <section class="min-sec d-none d-lg-block">
        <div class="container mb-5 p-5">

            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-9">
                    <div class="sec-heading">
                        <h2>More Resources</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>

            <!-- ============================ Join And Hire Start ==================================== -->
            <section class="p-0" >
                <div class="container-fluid">
                    <div class="row">

                            <div class="col-md-6 col-sm-6" style="background:#ddd;" >
                                <div class="call-to-act-caption">
                                    <h2 class="text-dark">Sabenzi Tutor</h2>
                                    <h3>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo</h3>
                                    <a href="#" class="btn bat-call-to-act">Learn more</a>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 " style="background:#e7ecf1;">
                                <div class="call-to-act-caption">
                                    <h2 class="text-dark">Sabenzi TDS </h2>
                                    <h3>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo</h3>
                                    <a href="#" class="btn bat-call-to-act">Learn more</a>
                                </div>
                            </div>


                    </div>
                </div>
            </section>
            <!-- ============================ Join And Hire End ==================================== -->

        </div>
    </section>
    <!-- ============================ News Updates End ==================================== -->

    <!-- ============================ Featured Employers Start ================================== -->
    <section class="featured-employers-wrap  mb-5 p-5 d-none d-lg-block" style="background:#f5f5f5; ">
        <div class="container ">
            <div class="row align-items-center ">

                <div class="col-lg-4 col-md-4 ">
                    <h5 class="mb-0">As featured in : </h5>
                </div>

                <div class="col-lg-8 col-md-8  p-2">
                    <div class="employer-brands" id="brands">
                        <ul class="pl-0">
                            <li class="single-employers mr-5">
                                <base-image
                                    imgName="airbnb.png"
                                    imgGeo="employers"
                                    imgClass="img-fluid"
                                    imgAlt="Featured employer"
                                 >
                                 </base-image>
                            </li>
                            <li class="single-employers mr-5">
                                <base-image
                                    imgName="spotify.png"
                                    imgGeo="employers"
                                    imgClass="img-fluid"
                                    imgAlt="Featured employer"
                                 >
                                 </base-image>
                            </li>
                            <li class="single-employers mr-5">
                                <base-image
                                    imgName="googl.png"
                                    imgGeo="employers"
                                    imgClass="img-fluid"
                                    imgAlt="Featured employer"
                                 >
                                 </base-image>
                            </li>
                            <li class="single-employers mr-5">
                                <base-image
                                    imgName="lenovo.png"
                                    imgGeo="employers"
                                    imgClass="img-fluid"
                                    imgAlt="Featured employer"
                                 >
                                 </base-image>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Featured Employers End ================================== -->
</template>

<script>
// @ is an alias to /src
import { ref,inject } from 'vue';
import { useStore } from 'vuex';

export default {
  name: "Home",
  setup(){

      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",true);
      store.dispatch("globals/executeSetFooterMenu",true);

      const axios = inject('axios');
      const coreUrl = inject('coreUrl');


      const contractType = {
          full_time:"bg-success",
          part_time:"bg-primary",
          consultancy:"bg-info",
          freelance:"bg-secondary"
      }



      const featuredJobOffers = ref("");

      const params = { par_total: 6 , order_type: 'random' }
      // use axios to get the data
      axios.get(
          coreUrl.backendUrl+'get_simple_job_ad_list',
          {withCredentials: true , params: params }).then((response) =>{

          const result = response.data.data.job_ads;

          // use the dispatch function to call an action that calls a mutation
          // to set the data of for the job ad
          featuredJobOffers.value = result


      }).catch((error)=>{
              console.log(error)
      })

      const placeholderOptions = {
          full_time:"salary per annum",
          part_time:"salary per annum",
          consultancy:"consultancy fee",
          freelance:"freelance fee",
          internship:"salary per annum",
          contract:"contract fee",
          volunteer:"volunteer fee"
      }


      const jobCategory = [
          {value:"engineering",alias:"Engineering"},
          {value:"health_care",alias:"Health care"},
          {value:"software_engineering",alias:"Software engineering"},
          {value:"humanitarian_ngo",alias:"Humanitarian / Ngo"},
          {value:"media_comms",alias:"Media & Comms"},
          {value:"finance",alias:"Finance"},
          {value:"operations",alias:"Operations"},
          {value:"legal",alias:"Legal"},
          {value:"real_estate",alias:"Real estate"},
          {value:"education",alias:"Education"},
          {value:"information_technology",alias:"Information technology"},
          {value:"marketing",alias:"Marketing"},
          {value:"sales",alias:"Sales"},
          {value:"research",alias:"Research"},
          {value:"supply_chain",alias:"Supply chain"},
          {value:"catering",alias:"Catering"},
          {value:"building_architecture",alias:"Building & Architecture"},
          {value:"sales",alias:"Sales"},
          {value:"arts_and_design",alias:"Arts and design"},
          {value:"other",alias:"other"}
      ]


      const priviledges = [
          {idea:"career",value:"Your dream job is waiting for you"},
          {idea:"organization",value:"Access to amazing talents"}
      ]


      const activeJobInfo = ref(true);

      function toggleActiveJobInfo(){
          activeJobInfo.value = !activeJobInfo.value;
      }

      return {
          coreUrl,
          contractType,
          activeJobInfo,
          toggleActiveJobInfo,
          featuredJobOffers,
          jobCategory,
          placeholderOptions,
          priviledges
      }
  }
};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.beige-bg{
    background: #f5f5f5;
    border-color: #ccc;
    color:#2D4379;
}

.beige-bg:hover{
    background: #ccc;
    border-color: #ccc;
    color:#2D4379;
}

.tiny_font{
    font-size:11px;
}



</style>
